// Adds a custom validator that requires a value to be true
// useful for "Accept" checkboxes
(function ($) {
    $.validator.addMethod("requiretrue", function (value, element, params) {
        if ($(element).is('[type=checkbox]')) {
            return (value === "true" || value === true) && $(element).is(':checked');
        }
        return false;
    });

    $.validator.unobtrusive.adapters.addBool("requiretrue");
})(jQuery);