// Configuration for unobtrusive validation to work with our custom validation messages and bootstrap
(function ($) {
    var defaultOptions = {
        errorClass: 'has-error',
        validClass: 'has-success',
        highlight: function (element, errorClass, validClass) {
            var $el = $(element),
                $group = $el.closest('.form-group'),
                $msg = $group.find('[data-valmsg-for]');
            $el.addClass(errorClass).removeClass(validClass);
            $group.addClass(errorClass).removeClass(validClass);
            if ($el.is('[type=text]')) {
                $group.addClass('has-feedback');
                $group.find('.form-control-feedback').addClass('glyphicon-remove').removeClass('glyphicon-ok');
            }
            $msg.show();
        },
        unhighlight: function (element, errorClass, validClass) {
            var $el = $(element),
                $group = $el.closest('.form-group'),
                $msg = $group.find('[data-valmsg-for]');
            $el.removeClass(errorClass).addClass(validClass);
            $group.removeClass(errorClass).addClass(validClass);
            if ($el.is('[type=text]')) {
                $group.addClass('has-feedback');
                $group.find('.form-control-feedback').addClass('glyphicon-ok').removeClass('glyphicon-remove');
            }
            $msg.hide();
        }
    };

    $.validator.setDefaults(defaultOptions);

    $.validator.unobtrusive.options = {
        errorClass: defaultOptions.errorClass,
        validClass: defaultOptions.validClass,
    };
})(jQuery);